	.scparallaxbg{
		width: 100%;
		height: 400px;
		position: relative;
		overflow: hidden;
	}
	.scparallaxbg>img,.scparallaxbg>a>img{
		position: absolute;
		top: 0px;
		left: 50%;
		-webkit-transform: translateX(-50%);
		    -ms-transform: translateX(-50%);
		        transform: translateX(-50%);
		-webkit-transition: top 0.01s;
		-o-transition: top 0.01s;
		transition: top 0.01s;
		z-index: 0;
	}
	.scparallaxbg>div{
		position: relative;
		z-index: 10;
	}
	.scparallaxbg:not(.no-height) .container,.scparallaxbg:not(.no-height) .container-fluid{
	 	position: relative;
	 	top: 50%;
	 	-webkit-transform: translateY(-50%);
	 	    -ms-transform: translateY(-50%);
	 	        transform: translateY(-50%);
	 	text-align: center;
	 	max-width: 100vw;
	}
	.scparallaxbg.bg::before, .scparallaxbg.bg-black::before{
	 	content: '';
	 	position: absolute;
	 	top: 0px;
	 	right: 0px;
	 	left: 0px;
	 	bottom: 0px;
	 	-webkit-transform: translateZ(0px);
	 	        transform: translateZ(0px);
	 	height: 100%;
	 	width: 100%;
	 	z-index: 1;
	 	background-color: rgba(255, 255, 255, 0.25);
	}
	.scparallaxbg.bg-black::before{
		background-color: rgba(0, 0, 0, 0.25);
	}