.scgallery{
	padding: 5px;
}
.scgallery[hidden]{
	display: block !important;
}

.scgallery .scgallery-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	width: 100%;
	margin: 0px;
}
.scgallery .scgallery-list .scgallery-item {
	overflow: hidden;
	-webkit-transition: all 0s;
	-o-transition: all 0s;
	transition: all 0s;
	padding: 5px;
}
.scgallery .scgallery-list .scgallery-item>*{
	-webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
	        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
}

.scgallery .scgallery-item.activeG{
	-webkit-filter: grayscale(100%);
	        filter: grayscale(100%);
}
.scgallery-2 .scgallery-list li {
	width: 50%;
	height: 50%;
}
.scgallery-3 .scgallery-list li {
	width: 33.33333%;
	height: 33.33333%;
}
.scgallery-4 .scgallery-list li {
	width: 25%;
}
.scgallery-5 .scgallery-list li {
	width: 20%;
	height: 20%;
}
.scgallery-6 .scgallery-list li {
	width: 16.66667%;
	height: 16.66667%;
}
.scgallery .scgallery-list .scgallery-item a {
	display: block;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	/*overflow: hidden;*/
}
#scgallery-view {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	padding: 25px 50px;
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 10000;
}
#scgallery-view * {
	-moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
    -webkit-touch-callout: none;
}
#scgallery-view #img-masc {
	overflow: hidden;
	position: absolute;
	height: 0px;
	width: 0px;
	-webkit-transform: translate3D(-50%, -50%, 0);
	    -ms-transform: translate3D(-50%, -50%, 0);
	        transform: translate3D(-50%, -50%, 0);
	-webkit-transition-property: height, width, top, left;
	-o-transition-property: height, width, top, left;
	transition-property: height, width, top, left;
	-webkit-transition-duration: 0.1s;
	     -o-transition-duration: 0.1s;
	        transition-duration: 0.1s;
	background-position: center;
	background-size: cover;
	-webkit-box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.25);
	        box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.25)
}
#scgallery-view #gv-close {
	color: white;
	font-size: 42px;
	opacity: 0.85;
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 10000;
}
#scgallery-view #gv-prev,#scgallery-view #gv-next {
	height: 50px;
    width: 45px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    background: rgba(0, 0, 0, 0.8);
    font-size: 30px;
    text-align: center;
    border: none;
    color: rgba(255,255,255,0.5);
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
}
#scgallery-view #gv-prev:hover,#scgallery-view #gv-next:hover{
	color:  white;
}
.carousel-control>.fa {
    line-height: 50px;
}
#scgallery-view #gv-prev {
	left: -45px;
}
#scgallery-view #gv-next {
	right: -45px;
}
#scgallery-view.activeG #gv-prev {
	left: 0px;
}
#scgallery-view.activeG #gv-next {
	right: 0px;
}
#scgallery-view #img-masc>#img {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	display: none;
}
@media(max-width: 768px) {
	.scgallery .scgallery-list li {
		width: 100%;
	}
	.scgallery .scgallery-list {
		padding-top: 0px;
		margin: auto;
	}
	.scgallery {
		height: auto;
	}
	#scgallery-view{
		position: fixed;
		height: auto;
		padding: 43px 5px;
	}
	#scgallery-view #gv-prev {
		border-right: 1px solid rgba(255, 255, 255, 0.4);
	}
	#scgallery-view #gv-close {
		top: 0px;
		left: 0px;
		right: 0px;
		font-size: 32px;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		position: absolute;
		height: 38px;
		font-weight: 900;
	}
	#scgallery-view #gv-prev, #scgallery-view #gv-next {
		top: auto;
		bottom: 0px;
		height: auto;
		line-height: 32px;
		font-size: 32px;
		width: 50%;
		background-color: rgba(0, 0, 0, 0.5);
	}
}
#scgallery-view #img-masc.loading::before{
    content: '';
    display: none;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    margin: auto;
    background: rgba(0, 0, 0, 0.55);
    z-index: 100;
    -webkit-transition: all 2s;
    -o-transition: all 2s;
    transition: all 2s;
}
#scgallery-view #img-masc.loading::after{
	content: '\f1ce';
	-webkit-animation: a 2s infinite linear;
	animation: a 2s infinite linear;
	font: normal normal normal 72px/1 "Font Awesome 5 Free";
	width: 72px;
	height: 72px;
	margin: auto;
	position: absolute;
	top: 0px; bottom: 0px; left: 0px; right: 0px;
	color: #f6f6f6;
	z-index: 100;
	font-weight: 900;
}