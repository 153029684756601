


























































.v-scs-slide {
    width: 100%;
    height: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    position: relative;
    text-align: center;
    overflow: hidden;
    position: relative;

    &:not(.active){
        display: none;
    }

    &.active,
    &.next {
        -webkit-transition: transform 0.5s;
        -o-transition: transform 0.5s;
        transition: transform 0.5s;
    }

    .v-scs-img {
        position: absolute;
        top: 0px;
        bottom: 0px;
        right: 0px;
        left: 0px;
        margin: auto;
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
    }

    & > * {
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-user-drag: none;
        user-drag: none;
        -webkit-touch-callout: none;
    }
}
