@import "bootstrap-theme";
@import "style";

abbr {
	text-decoration: none;
}

abbr:hover{
	cursor: default;
}

html, body {
  height: 100%;
}

body {
  padding: 0px;
}

body,
#app {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  position: relative;
  min-height: 100vh;
}

#app{
  padding-top: 92px;
}

#app.scrolled{
  /*padding-top: 67px;*/
}

.modal{
  .modal-content{
    border-radius: 0;
  }
  .modal-header{
    background-repeat: repeat;
    position: relative;
    border-radius: 0;
    border-bottom: none;
  }
  .modal-footer{
    background-repeat: repeat;
    border-top: none;
    position: relative;
    padding-top: 25px;
    border-radius: 0;
  }
}

header.header {
  background-repeat: repeat;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  .lang a{
    line-height: 1;
  }
  .lang img {
    width: 25px;
    margin-right: 5px;
  }
}

.scrolled header.header .top-header{
  height: 0;
  padding-bottom: 0px;
}

.form-group .form-control,
.form-group .custom-select {
  // background-color: $theme-secondary;
  // border-color: $theme-other;
  // border-width: 3px;
}

.input-group .input-group-text{
  // background-color: $theme-other;
  // border-color: $theme-other;
  // color: $theme-secondary;
}

span.jq-dte span.jq-dte-tooltip{
  background-color: #f5f3eb;
  border-color: #dad7c0;
  opacity: .8;
}

.form-group .form-control:focus + label.floating-label,
.form-group .form-control:not(:placeholder-shown) + label.floating-label,
.form-group .custom-select:focus + label.floating-label, .form-group .custom-select:not(:placeholder-shown) + label.floating-label{
  // background-color: rgba($theme-secondary, .85);
}

.form-group label.floating-label{
  top: 0.22rem;
  left: 0.2rem;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c4c1ac;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #c4c1ac;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #c4c1ac;
}

footer.footer {
  position: relative;
  border: none;
  padding-top: 11px;
  color: #333;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  padding-bottom: 1rem;
  background: $theme-other;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: white;
  font-weight: 400;

  .footer-top {
    background: $theme-other;
    position: relative;
  }

  .footer-bottom {
    padding-top: 2.5rem;
    position: relative;
  }
}

.social .social-item {
  .social-bg {
    border-radius: 50%;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    padding: .25rem;
    margin: .25rem;

    a {
      color: inherit;
      -webkit-transition: all .5s ease;
      -o-transition: all .5s ease;
      transition: all .5s ease;
    }

    svg.svg-inline--fa {
      width: 1.5rem;
    }
  }

  &:hover .social-bg {
    background-color: #f6f6f6 !important;
  }

  &:not(:hover) .social-bg {
    color: #fff !important;
  }
}

.scs-indicators li.active:before {
  width: 100%;
  -webkit-transition: width 8s linear;
  -o-transition: width 8s linear;
  transition: width 8s linear;
}

.navbar {
  margin: auto;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: $theme-secondary;
  position: relative;
  z-index: auto;
  padding-bottom: 11px;
  max-height: 92px;

  .navbar-brand {
    padding: 4px !important;
    padding-top: 7px !important;
    height: auto;

    img {
      height: 70px;
      -webkit-transition: all .5s ease;
      -o-transition: all .5s ease;
      transition: all .5s ease;
      width: initial;
    }
  }

  .navbar-collapse .navbar-nav {
    font-size: 14px;
    > .nav-item {
      z-index: auto;

      > .nav-link {
        -webkit-transition: color 0.2s;
        -o-transition: color 0.2s;
        transition: color 0.2s;
        padding: 0px 18px;
        height: auto;
        line-height: 1;
        display: inline-block;
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;
        color: #474747;
        width: 100%;
      }

      > .nav-link a:hover,
      &:hover > .nav-link {
        background: transparent;
        color: $theme-primary !important;
        font-weight: bold;
      }

      &.active > .nav-link,
      &.show > .nav-link {
        background: transparent;
        color: $theme-primary !important;
        font-weight: bold;
      }

      &:not(.active) > .nav-link:not(:hover) .nav-link-icon {
        color: #ccc !important;
      }

      @media (max-width: 1199px) {
        &:not(.active) > .nav-link:not(:hover) .nav-link-icon {
          color: #000 !important;
        }
      }

      > .nav-link .nav-link-icon {
        font-size: 24pt;
        padding: .75rem 0px;
        -webkit-transition: all .5s ease;
        -o-transition: all .5s ease;
        transition: all .5s ease;
      }
    }
  }

  .navbar-toggler {
    transition: top .5s ease, transform .5s ease;
    top: 12.5px;
    transform: translateX(100%);
  }

  .out .navbar-toggler {
    transform: translateX(0);
  }
}
.scrolled .navbar{
  padding-bottom: 6px;

  @media (min-width: 1200px) {
    .navbar-collapse .navbar-nav > .nav-item > .nav-link .nav-link-icon {
      font-size: 18pt;
      padding: .45rem 0px;
    }
  }

  @media (min-width: 1200px) {
    .navbar-collapse .navbar-nav {
      font-size: 12px;
    }
  }

  .navbar-brand img{
    height: 50px;
  }
  .scrolled .navbar{
    padding-bottom: 6px;
  }
  .navbar-toggler{
    top: 0;
  }
}

.shadowed{
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
}

.bg-faded{
  background: rgba(255,255,255,0.6);
}

@supports (-webkit-backdrop-filter: blur(10px)){
  .bg-faded{
    background: rgba(255,255,255,0.3);
    -webkit-backdrop-filter: blur(5px);
  }
}

.input-group .input-group-text,
.theme-tone{
  --fa-primary-color: #{$theme-alternative};
  --fa-secondary-color: #{$theme-primary};
  --fa-secondary-opacity: 1.0;
}

.theme-swamp-tone{
  --fa-primary-color: #{$theme-primary};
  --fa-secondary-color: #{$theme-secondary};
  --fa-secondary-opacity: 1.0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to

  /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.slide-vertical-enter-active, .slide-vertical-leave-active {
  transition: transform .5s;
}
.slide-vertical-enter, .slide-vertical-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translate3d(0, 100%, 0);
}

.yellow{
  color: #FF9100;
}

.extra-small{
  font-size: 50%;
}

.titular{
  border-bottom: 1px solid #cdcbc3;
  display: flex;
  align-items: center;
}

.titular::before{
  content: "\f45c";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 0.5rem;
  font-weight: 900;
  margin-right: 0.5rem;
  color: #FF9100;
  font-family: "Font Awesome 5 Pro";
}

.btn{
  border-radius: 0;
  border-width: 2px;
}

.dropdown-menu{
  border-radius: 0;
  box-shadow: 0px 5px 10px 1px rgba(0,0,0,.15);
}

.item.active,
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: $theme-primary;
}

.cc-window{
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.25);
}


.list-fluna .list-group-item {
  background: rgba(255,255,255,0.6);
}

.list-transparent .list-group-item {
  background: transparent;
}

@supports (-webkit-backdrop-filter: blur(10px)){
  .list-fluna .list-group-item {
    background: rgba(255,255,255,0.3);
    -webkit-backdrop-filter: blur(5px);
  }
}

.img-thumbnail{
  border-radius: 0;
  padding: .35rem;
}

#goUp{
  position: fixed;
  left: 0;
  bottom: 0;
  right: auto;
  z-index: 100;
  font-size: 80%;

  g {
    color: white;
  }
}

/*img:not(.not-faded){
  @extend .bg-faded;
  text-align: center;
}*/

.wide-slider{
  width: 100%;
  position: relative; /* If you want text inside of it */
  overflow: hidden;
  background-position: center;
  background-size: cover;
  padding-bottom: 32.76%;
}

@media (max-width: 768px) {
  .wide-slider {
    padding-bottom: 75%;
  }
}

.wide-slider>.v-scslider,
.wide-slider>.scslider{
  position: absolute !important;
  top: 0;left: 0;right: 0;bottom: 0;
  margin: auto;
  width: 100%; height: 100%;
}

.scs-control{
  background: rgba(247, 247, 247, .8);
  -webkit-backdrop-filter: blur(5px);
  color: #333;
  background-image: none !important;
}

.carrousel-control{
  -webkit-backdrop-filter: blur(5px);
  color: #333;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  text-align: center;
  position: absolute;
  top: 0;
  left: -5px;
  right: auto;
  bottom: 32px;
  line-height: 50px;
  z-index: 5;
  transform: none;
  margin: auto;
  font-size: 24px;
  opacity: 0.5;

  &.carrousel-arrows-inside{
    opacity: .6;
    color: #f6f6f6;
  }

  &.carrousel-no-dots{
    bottom: 0px;
  }

  &:hover{
    opacity: 1;
    color: #333;
    height: fit-content;
  }

  &.carrousel-arrows-inside:hover{
    color: #f6f6f6;
  }

  &.left{
    transform: translateX(-100%);
  }
  &.carrousel-arrows-inside{
    left: 0px;
    transform: none;
  }

  &.right{
    left: auto;
    right: -5px;
    transform: translateX(100%);
  }
  &.right.carrousel-arrows-inside{
    right: 0px;
    transform: none;
  }

  &:before{
    content: none;
  }
}