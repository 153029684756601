









































































































































































.sc-image-parallax {
	position: relative;
	scroll-behavior: smooth;
	overflow: hidden;
	//z-index: -1;

	.sc-image-parallax-content {
		position: relative;
		z-index: 10;
		&.auto {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			margin: auto;
			width: 100%;
			height: 100%;
			z-index: 10;
		}
	}

	.sc-image-parallax-image {
		width: 100%;
		overflow: hidden;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			max-width: none;
			object-fit: cover;
			object-position: center;
		}

		&.is-parallax {
			left: 0;
			position: absolute;
			will-change: transform;
			right: 0;
			top: 0;
			bottom: 0;
			// -webkit-transition: transform 0.01s;
			// -o-transition: transform 0.01s;
			// transition: transform 0.01s;
			z-index: 0;
		}

		&.is-fixed {
			position: fixed;
			will-change: transform;
			> img {
				height: 100vh;
				max-width: none;
			}
		}
	}

	&.bg::before,
	&.bg-black::before {
		content: '';
		position: absolute;
		top: 0px;
		right: 0px;
		left: 0px;
		bottom: 0px;
		-webkit-transform: translateZ(0px);
		transform: translateZ(0px);
		height: 100%;
		width: 100%;
		z-index: 1;
		background-color: rgba(255, 255, 255, 0.25);
	}
	&.bg-black::before {
		background-color: rgba(0, 0, 0, 0.25);
	}
}
