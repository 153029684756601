/*===========================================================
				Switch
==========================================================*/
.switch {
	position: relative;
	margin: 0px 5px;
	display: table;
	width: 60px;
	height: 34px;
	font-size: 14px;
}
.input-group-addon .switch,
.switch-label.small .switch,
.switch.small{
	width: 40px;
	height: 22px;
	font-size: 10px;
	display: table !important;
	margin: 0px;
}
.switch input {
	display:none;
}

.ws-nowrap{
	white-space: nowrap;
}

.card-body>.form-group:last-child{
	margin-bottom: 0px;
}

.switch .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	-o-transition: .4s;
	transition: .4s;
	line-height: 20px;
}
.switch .slider::before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	-o-transition: .4s;
	transition: .4s;
}
.switch .slider span {
	width: 50%;
	display: inline-block;
	text-align: center;
	height: 100%;
	line-height: 34px;
	vertical-align: middle;
	font-weight: 300;
	letter-spacing: initial;
}
.switch .slider span:first-child{
	color: white;
}
.input-group-addon .switch .slider::before,
.switch-label.small .switch .slider::before,
.switch.small .slider::before{
	height: 18px;
	width: 18px;
	left: 2px;
	bottom: 2px;
}
.input-group-addon .switch .slider span,
.switch-label.small .switch .slider span,
.switch.small .slider span {
	line-height: 22px;
}

.switch input:checked + .slider {
	background-color: #2196F3;
}
.switch input:checked + .slider.danger {
	background-color: #dc3545;
}
.switch input:checked + .slider.warning {
	background-color: #ffc107;
}
.switch input:checked + .slider.success {
	background-color: #28a745;
}
.switch input:checked + .slider.light {
	background-color: #f8f9fa;
}
.switch input:checked + .slider.secondary {
	background-color: #868e96;
}
.switch input:checked + .slider.dark {
	background-color: #343a40;
}

.switch input:focus + .slider {
	-webkit-box-shadow: 0 0 1px #2196F3;
					box-shadow: 0 0 1px #2196F3;
}
.switch input:focus + .slider.danger {
	-webkit-box-shadow: 0 0 1px #dc3545;
					box-shadow: 0 0 1px #dc3545;
}
.switch input:focus + .slider.warning {
	-webkit-box-shadow: 0 0 1px #ffc107;
					box-shadow: 0 0 1px #ffc107;
}
.switch input:focus + .slider.success {
	-webkit-box-shadow: 0 0 1px #28a745;
					box-shadow: 0 0 1px #28a745;
}
.switch input:focus + .slider.light {
	-webkit-box-shadow: 0 0 1px #f8f9fa;
					box-shadow: 0 0 1px #f8f9fa;
}
.switch input:focus + .slider.secondary {
	-webkit-box-shadow: 0 0 1px #868e96;
					box-shadow: 0 0 1px #868e96;
}
.switch input:focus + .slider.dark {
	-webkit-box-shadow: 0 0 1px #343a40;
					box-shadow: 0 0 1px #343a40;
}

 .switch input:checked + .slider::before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

.input-group-addon  .switch input:checked + .slider::before,
.switch-label.small .switch input:checked + .slider::before,
.switch.small input:checked + .slider::before{
	-webkit-transform: translateX(18px);
	-ms-transform: translateX(18px);
	transform: translateX(18px);
}

/* Rounded sliders */
.switch .slider.round {
	border-radius: 34px;
}

.input-group-addon  .switch .slider.round,
.switch-label.small .switch .slider.round,
.switch.small .slider.round {
	border-radius: 22px;
}

.input-group-various-groups.is-valid .form-control{
	border-color: #28a745;
}

.switch .slider.round::before {
	border-radius: 50%;
}
.switch-label{
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
			-ms-flex-align: center;
					align-items: center;
	.text {
		margin-left: 0.35rem;
	}
}
.switch-label.top{
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
			-ms-flex-direction: column-reverse;
					flex-direction: column-reverse;
	.text {
		margin-bottom: 0.35rem;
	}
}
.switch-label.bottom{
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
			-ms-flex-direction: column;
					flex-direction: column;
	.text {
		margin-top: 0.35rem;
	}
}
.switch-label.left{
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
			-ms-flex-direction: row-reverse;
					flex-direction: row-reverse;

	.text {
		margin-right: 0.35rem;
	}
}

/*======================================
							Form Validation
======================================*/

.form-group.is-invalid > .invalid-feedback{
	display: block;
}


/*======================================
							Input Group
======================================*/

.input-group-field {
	position: relative;
	-ms-flex: 1 1 auto;
	-webkit-box-flex: 1;
					flex: 1 1 auto;
	width: 1%;
	margin-bottom: 0;
}
.input-group > .input-group-field:not(:first-child) > .custom-select, .input-group > .input-group-field:not(:first-child) > .form-control{
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
}

.input-group > .input-group-field:not(:last-child) > .custom-select, .input-group > .input-group-field:not(:last-child) > .form-control{
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
}

.input-group{
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

/*======================================
					Input Group Varius
======================================*/

.input-group-varius-groups>.form-control:not(:last-child),
.input-group-varius-groups>.bootstrap-select:not(:last-child)>.dropdown-toggle
.input-group-varius-groups>.input-group:not(:last-child)>.form-control,
.input-group-varius-groups>.input-group:not(:last-child) .bootstrap-select>.dropdown-toggle{
	border-bottom: none;
}

.input-group-varius-groups>.form-control:first-child:not(:last-child),
.input-group-varius-groups>.bootstrap-select:first-child:not(:last-child)>.dropdown-toggle,
.input-group-varius-groups>.btn:first-child:not(:last-child),

.input-group-varius-groups>.input-group:first-child:not(:last-child)>.form-control,
.input-group-varius-groups>.input-group:first-child:not(:last-child) .input-group-text,
.input-group-varius-groups>.input-group:first-child:not(:last-child) .btn,
.input-group-varius-groups>.input-group:first-child:not(:last-child) .bootstrap-select>.dropdown-toggle,

.input-group-varius-groups>.form-control:not(:first-child):not(:last-child),
.input-group-varius-groups>.bootstrap-select:not(:first-child):not(:last-child)>.dropdown-toggle,
.input-group-varius-groups>.btn:not(:first-child):not(:last-child),

.input-group-varius-groups>.input-group:not(:first-child):not(:last-child)>.form-control,
.input-group-varius-groups>.input-group:not(:first-child):not(:last-child) .input-group-text,
.input-group-varius-groups>.input-group:not(:first-child):not(:last-child) .btn,
.input-group-varius-groups>.input-group:not(:first-child):not(:last-child) .bootstrap-select>.dropdown-toggle {
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
}
.input-group-varius-groups>.form-control:last-child:not(:first-child),
.input-group-varius-groups>.bootstrap-select:last-child:not(:first-child)>.dropdown-toggle,
.input-group-varius-groups>.btn:last-child:not(:first-child),

.input-group-varius-groups>.input-group:last-child:not(:first-child)>.form-control,
.input-group-varius-groups>.input-group:last-child:not(:first-child) .input-group-text,
.input-group-varius-groups>.input-group:last-child:not(:first-child) .btn,
.input-group-varius-groups>.input-group:last-child:not(:first-child) .bootstrap-select>.dropdown-toggle,

.input-group-varius-groups>.form-control:not(:last-child):not(:first-child),
.input-group-varius-groups>.bootstrap-select:not(:last-child):not(:first-child)>.dropdown-toggle,
.input-group-varius-groups>.btn:not(:last-child):not(:first-child),

.input-group-varius-groups>.input-group:not(:last-child):not(:first-child)>.form-control,
.input-group-varius-groups>.input-group:not(:last-child):not(:first-child) .input-group-text,
.input-group-varius-groups>.input-group:not(:last-child):not(:first-child) .btn,
.input-group-varius-groups>.input-group:not(:last-child):not(:first-child) .bootstrap-select>.dropdown-toggle {
	border-top-right-radius: 0px;
	border-top-left-radius: 0px;
}

/*======================================
							Floating label
======================================*/

.form-group{
	position: relative;
}

.form-group label.floating-label{
	position: absolute;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	top: 1px;
	left: 1px;
	color: #6c757d;
	max-height: 30px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100%;
	padding: 0.375rem 0.75rem;
}

.form-group .form-control:focus + label.floating-label,
.form-group .form-control:not(:placeholder-shown) + label.floating-label,
.form-group .custom-select:focus + label.floating-label,
.form-group .custom-select:not(:placeholder-shown) + label.floating-label{
	top: -.75rem;
	font-size: 80%;
	padding: 0 6px;
	background: white;
	color: #333;
	z-index: 1;
	line-height: inherit;
}

/*======================================
	      Number control
======================================*/

.form-group .form-control.number-control {
	width: 5rem;
}

.pre-wrap{
	white-space: pre-wrap;
}

.text-nowrap{
	white-space: nowrap;
}

.info-p{
	padding-left: 1.75em;
	text-align: justify;
	display: inline-block;
}

.info-p::before{
	content: "\f05a";
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
	margin-right: .3em;
	margin-left: -1.35em;
}

.info-msg{
	@extend .info-p;
	margin-top: 0.5rem;
	font-size: 80%;
	color: #9fadbb;
	width: 100%;
}

.border-color{
	border-color: currentColor !important;
}