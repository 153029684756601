@import "../../common-assets/scss/bootstrap-extension";
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600&display=swap');

@font-face {
	font-family: "Font Awesome 5 Brands";
	font-style: normal;
	font-weight: normal;
	font-display: auto;
	src: url(../../common-assets/webfonts/fa-brands-400.eot);
	src: url(../../common-assets/webfonts/fa-brands-400.eot?#iefix) format("embedded-opentype"), url(../../common-assets/webfonts/fa-brands-400.woff2) format("woff2"), url(../../common-assets/webfonts/fa-brands-400.woff) format("woff"), url(../../common-assets/webfonts/fa-brands-400.ttf) format("truetype"), url(../../common-assets/webfonts/fa-brands-400.svg#fontawesome) format("svg")
}

@font-face {
	font-family: "Font Awesome 5 Duotone";
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url(../../common-assets/webfonts/fa-duotone-900.eot);
	src: url(../../common-assets/webfonts/fa-duotone-900.eot?#iefix) format("embedded-opentype"), url(../../common-assets/webfonts/fa-duotone-900.woff2) format("woff2"), url(../../common-assets/webfonts/fa-duotone-900.woff) format("woff"), url(../../common-assets/webfonts/fa-duotone-900.ttf) format("truetype"), url(../../common-assets/webfonts/fa-duotone-900.svg#fontawesome) format("svg")
}

@font-face {
	font-family: "Font Awesome 5 Pro";
	font-style: normal;
	font-weight: 300;
	font-display: auto;
	src: url(../../common-assets/webfonts/fa-light-300.eot);
	src: url(../../common-assets/webfonts/fa-light-300.eot?#iefix) format("embedded-opentype"), url(../../common-assets/webfonts/fa-light-300.woff2) format("woff2"), url(../../common-assets/webfonts/fa-light-300.woff) format("woff"), url(../../common-assets/webfonts/fa-light-300.ttf) format("truetype"), url(../../common-assets/webfonts/fa-light-300.svg#fontawesome) format("svg")
}

@font-face {
	font-family: "Font Awesome 5 Pro";
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(../../common-assets/webfonts/fa-regular-400.eot);
	src: url(../../common-assets/webfonts/fa-regular-400.eot?#iefix) format("embedded-opentype"), url(../../common-assets/webfonts/fa-regular-400.woff2) format("woff2"), url(../../common-assets/webfonts/fa-regular-400.woff) format("woff"), url(../../common-assets/webfonts/fa-regular-400.ttf) format("truetype"), url(../../common-assets/webfonts/fa-regular-400.svg#fontawesome) format("svg")
}

@font-face {
	font-family: "Font Awesome 5 Pro";
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url(../../common-assets/webfonts/fa-solid-900.eot);
	src: url(../../common-assets/webfonts/fa-solid-900.eot?#iefix) format("embedded-opentype"), url(../../common-assets/webfonts/fa-solid-900.woff2) format("woff2"), url(../../common-assets/webfonts/fa-solid-900.woff) format("woff"), url(../../common-assets/webfonts/fa-solid-900.ttf) format("truetype"), url(../../common-assets/webfonts/fa-solid-900.svg#fontawesome) format("svg")
}

/*{
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;

	text-align: center;
    width: 1.25em;

    font-weight: 300;
    font-weight: 400;
    font-weight: 900;

    font-family: "Font Awesome 5 Brands";
    font-family: "Font Awesome 5 Pro";
    font-family: "Font Awesome 5 Duotone";
}
 */

/*========================================================
			Global
=========================================================*/

body {
	background-color: #f7f7f7;
	color: #333;
	font-family: 'Raleway', sans-serif;
	font-weight: 400;
	overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5 {
	font-weight: 700;
	text-transform: uppercase;
	margin: 10px 0 20px;
}
a {
	font: inherit;
	color: inherit;
	text-decoration: none;
	-webkit-transition: color 0.1s ease;
	-o-transition: color 0.1s ease;
	transition: color 0.1s ease
}
a:hover {
	color: $theme-primary;
	text-decoration: none;
}
body:not(#tinymce) .animate{
	visibility: hidden;
}

main#wrapper {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	position: relative;
	min-height: 1%;
}

main#wrapper>*:first-child:nth-last-child(2){
	height: 100%;
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
}

.iziToast-wrapper-topRight,
.iziToast-wrapper-topLeft,
.iziToast-wrapper-topCenter{
	padding-top: 85px;
	max-height: 100%;
	overflow: scroll;
}

.input-group .input-group-text{
	background: #fff;
	color: $theme-other;
	border-color: $theme-other;
}
.form-group .form-control,
.form-group .custom-select
{
	background-color: #fff;
	color: $theme-other;
	outline: none;
	font-size: 18px;
	border-color: $theme-other;
	text-overflow: ellipsis;
	-webkit-box-shadow: none;
	        box-shadow: none;
}

.form-check-label{
	flex-shrink: 1000000;
}

.form-group label.floating-label{
	max-height: 44px;
}

.big{
	font-size: 180%;
}

.form-group {
	input.form-control,
	select.custom-select,
	textarea.form-control,
	.form-control input,
	.form-control textarea,
	.form-control {
		height: auto;
		line-height: 30px;
	}

	&.small select.custom-select,
	&.small input.form-control,
	&.small .form-control input{
		height: 34px;
	}

	&.small .custom-select,
	&.small .form-control {
		padding: 4px 10px;
		font-size: 14px;
	}

	.custom-select:focus
	.form-control:focus {
		-webkit-box-shadow: none;
		box-shadow: none;
		outline: none;
	}

	.floating-label {
		font-size: 18px;
		line-height: 30px;
	}
}
.form-group .form-control.bootstrap-select .custom-select,
.form-group .form-control.bootstrap-select .dropdown-toggle{
	height: 100%;
	border: none;
	background: transparent;
}

.g-recaptcha {
	margin-bottom: 15px;
}
.sb-1,
.sb-2,
.sb-3,
.sb-4,
.sb-5{
	display: block;
	position: relative;
	color: #333;
}
.sb-1{
	padding-top: 2rem;
}
.sb-2{
	padding-top: 4rem;
}
.sb-3{
	padding-top: 8rem;
}
.sb-4{
	padding-top: 12rem;
}
.sb-5{
	padding-top: 16rem;
}
.sb-line:after {
	content: '';
	display: block;
	background: -webkit-gradient(linear, left top, right top, from(rgba(255,255,255,0)), color-stop(15%, currentColor), color-stop(50%, currentColor), color-stop(85%, currentColor), to(rgba(255,255,255,0)));
	background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, currentColor 15%, currentColor 50%, currentColor 85%, rgba(255,255,255,0) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, currentColor 15%, currentColor 50%, currentColor 85%, rgba(255,255,255,0) 100%);
	height: 1px;
	width: 80%;
	position: absolute;
	left: 0px;
	right: 0px;
	margin: auto;
	top: 50%;
}

/*========================================
	Sliders
==========================================*/

.carousel-full-screen {
	height: 100vh;
	padding: 0px;
	padding-top: 92px;
	margin-top: -92px;
}

.carousel-full-screen .slider{
	height: 100%;
}

/*========================================
	NavBar
==========================================*/

body {
	padding-top: 80px;
}
body#tinymce{
	padding: 15px;
}

.dropdown {
	.dropdown-menu{
		a.dropdown-item {
			-webkit-transition: background-color 0.1s ease;
			-o-transition: background-color 0.1s ease;
			transition: background-color 0.1s ease;
		}
	}
}

.navbar {
	margin: 0px;
	background: rgba(255, 255, 255, .9);
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	border: none;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 1000;
	padding: 0px;
	-webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
	box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);

	.navbar-header {
		position: relative;
		left: 0px;
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
	}

	.navbar-brand {
		height: 80px;
	}

	.navbar-brand {
		padding: 5px !important;
		margin: auto;
	}

	.navbar-brand img {
		height: 100%;
		width: auto;
	}

	.navbar-collapse {
		.navbar-nav {
			font-size: 18px;
			font-weight: 400;
			position: relative;

			> .nav-item {
				margin: 3px 3px;
				position: relative;
				z-index: 10;

				> .nav-link {
					-webkit-transition: color 0.2s;
					-o-transition: color 0.2s;
					transition: color 0.2s;
					padding: 0px 18px;
					height: 74px;
					line-height: 74px;
					display: inline-block;
					text-align: center;
					text-transform: uppercase;
					white-space: nowrap;
					color: #474747;
					width: 100%;
				}
			}
		}

		.nav .dropdown-menu {
			-webkit-box-shadow: none;
			box-shadow: none;
			border: 1px solid rgba(0, 0, 0, 0.15);
			border-top: 0px;
			border-radius: 0px;
			min-width: 100%;

			&.dropright .dropdown-menu {
				border-top: 1px;
			}

			> .dropdown-item > a {
				-webkit-transition: color 0.2s;
				-o-transition: color 0.2s;
				transition: color 0.2s;
				display: inline-block;
				text-align: center;
				text-transform: uppercase;
			}
		}
	}

	.dropdown-toggle:focus {
		outline: none;
	}

	.dropdown-menu {
		.active {
			background-color: transparent !important;
			color: #edc951;
		}

		.dropdown-item {
			&.dropright {
				padding: 0px;

				> .dropdown-toggle {
					padding: .25rem 1.5rem;
					width: 100%;
				}
			}
		}

		&.active,
		&.show {
			color: #ddae3e;
		}

		&:hover {
			background-color: #fbf4dc !important;
			color: #edc951;
		}

		a:hover {
			color: #edc951;
		}

		&:active a:hover {
			color: #fff;
		}

		&:focus,
		&:active {
			background-color: #edc951 !important;
			color: #fff;
		}
	}

	.navbar-collapse.out {
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
	}
}
@media (max-width: 1199px){
	.navbar-expand-xl {
		.navbar-collapse {
			display: block;
			position: fixed;
			background: rgba(255, 255, 255, .9);
			top: 0;
			bottom: 0;
			left: 0;
			width: 220px;
			max-width: 100%;
			left: 0;
			-webkit-transform: translateX(-100%);
			-ms-transform: translateX(-100%);
			transform: translateX(-100%);
			-webkit-transition: -webkit-transform 0.35s;
			transition: -webkit-transform 0.35s;
			-o-transition: transform 0.35s;
			transition: transform 0.35s;
			transition: transform 0.35s, -webkit-transform 0.35s;
			text-align: right;
			-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .25);
			box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .25);

			.nav {
				.dropdown-menu {
					background: transparent;
					-webkit-box-shadow: none;
					box-shadow: none;
					border: none;
					text-align: center;
				}

				.nav-item {
					padding: 15px 0;
					
					> .nav-link {
						text-align: left;
						height: auto;
						line-height: 1;
						padding-top: 10px;
						padding-bottom: 10px;
					}
				}
			}

			.navbar-nav {
				max-height: 100%;
				overflow-y: scroll;
				overflow-x: visible;
				display: block;
			}
		}

		@supports (-webkit-backdrop-filter: blur(40px)) {
			.navbar-collapse {
				background: transparent;
				-webkit-backdrop-filter: blur(40px);
			}
		}

		.dropdown-menu {
			padding-left: 10px;

			.dropdown-item {
				text-align: left;
			}

			.dropdown-item:hover,
			.dropdown-item.active,
			.dropdown-item.show {
				background-color: transparent !important;
				color: #ddae3e !important;
			}
		}

		.dropright .dropdown-toggle::after {
			border-top: .3em solid;
			border-right: .3em solid transparent;
			border-bottom: 0;
			border-left: .3em solid transparent;
		}

		.lang {
			position: absolute;
			right: 0;
		}
	}
}
@media (max-width: 991px){
	.navbar-expand-lg {
		.navbar-collapse {
			display: block;
			position: fixed;
			background: rgba(255, 255, 255, .9);
			top: 0;
			bottom: 0;
			left: 0;
			width: 220px;
			max-width: 100%;
			left: 0;
			-webkit-transform: translateX(-100%);
			-ms-transform: translateX(-100%);
			transform: translateX(-100%);
			-webkit-transition: -webkit-transform 0.35s;
			transition: -webkit-transform 0.35s;
			-o-transition: transform 0.35s;
			transition: transform 0.35s;
			transition: transform 0.35s, -webkit-transform 0.35s;
			text-align: right;
			-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .25);
			box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .25);

			.nav {
				.dropdown-menu {
					background: transparent;
					-webkit-box-shadow: none;
					box-shadow: none;
					border: none;
					text-align: center;
				}

				.nav-item > .nav-link {
					text-align: left;
					height: auto;
					line-height: 1;
					padding-top: 10px;
					padding-bottom: 10px;
				}
			}

			.navbar-nav {
				max-height: 100%;
				overflow-y: scroll;
				overflow-x: visible;
				display: block;
			}
		}

		@supports (-webkit-backdrop-filter: blur(40px)) {
			.navbar-collapse {
				background: transparent;
				-webkit-backdrop-filter: blur(40px);
			}
		}

		.dropdown-menu {
			padding-left: 10px;

			.dropdown-item {
				text-align: left;
			}

			.dropdown-item:hover,
			.dropdown-item.active,
			.dropdown-item.show {
				background-color: transparent !important;
				color: #ddae3e !important;
			}
		}

		.dropright .dropdown-toggle::after {
			border-top: .3em solid;
			border-right: .3em solid transparent;
			border-bottom: 0;
			border-left: .3em solid transparent;
		}

		.lang {
			position: absolute;
			right: 0;
		}
	}
}
@media (max-width: 767px){
	.navbar-expand-md {
		.navbar-collapse {
			display: block;
			position: fixed;
			background: rgba(255, 255, 255, .9);
			top: 0;
			bottom: 0;
			left: 0;
			width: 220px;
			max-width: 100%;
			left: 0;
			-webkit-transform: translateX(-100%);
			-ms-transform: translateX(-100%);
			transform: translateX(-100%);
			-webkit-transition: -webkit-transform 0.35s;
			transition: -webkit-transform 0.35s;
			-o-transition: transform 0.35s;
			transition: transform 0.35s;
			transition: transform 0.35s, -webkit-transform 0.35s;
			text-align: right;
			-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .25);
			box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .25);

			.nav {
				.dropdown-menu {
					background: transparent;
					-webkit-box-shadow: none;
					box-shadow: none;
					border: none;
					text-align: center;
				}

				.nav-item > .nav-link {
					text-align: left;
					height: auto;
					line-height: 1;
					padding-top: 10px;
					padding-bottom: 10px;
				}
			}

			.navbar-nav {
				max-height: 100%;
				overflow-y: scroll;
				overflow-x: visible;
				display: block;
			}
		}

		@supports (-webkit-backdrop-filter: blur(40px)) {
			.navbar-collapse {
				background: transparent;
				-webkit-backdrop-filter: blur(40px);
			}
		}

		.dropdown-menu {
			padding-left: 10px;

			.dropdown-item {
				text-align: left;
			}

			.dropdown-item:hover,
			.dropdown-item.active,
			.dropdown-item.show {
				background-color: transparent !important;
				color: #ddae3e !important;
			}
		}

		.dropright .dropdown-toggle::after {
			border-top: .3em solid;
			border-right: .3em solid transparent;
			border-bottom: 0;
			border-left: .3em solid transparent;
		}

		.lang {
			position: absolute;
			right: 0;
		}
	}
}
@media (max-width: 575px){
	.navbar-expand-sm {
		.navbar-collapse {
			display: block;
			position: fixed;
			background: rgba(255, 255, 255, .9);
			top: 0;
			bottom: 0;
			left: 0;
			width: 220px;
			max-width: 100%;
			left: 0;
			-webkit-transform: translateX(-100%);
			-ms-transform: translateX(-100%);
			transform: translateX(-100%);
			-webkit-transition: -webkit-transform 0.35s;
			transition: -webkit-transform 0.35s;
			-o-transition: transform 0.35s;
			transition: transform 0.35s;
			transition: transform 0.35s, -webkit-transform 0.35s;
			text-align: right;
			-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .25);
			box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .25);

			.nav {
				.dropdown-menu {
					background: transparent;
					-webkit-box-shadow: none;
					box-shadow: none;
					border: none;
					text-align: center;
				}

				.nav-item > .nav-link {
					text-align: left;
					height: auto;
					line-height: 1;
					padding-top: 10px;
					padding-bottom: 10px;
				}
			}

			.navbar-nav {
				max-height: 100%;
				overflow-y: scroll;
				overflow-x: visible;
				display: block;
			}
		}

		@supports (-webkit-backdrop-filter: blur(40px)) {
			.navbar-collapse {
				background: transparent;
				-webkit-backdrop-filter: blur(40px);
			}
		}

		.dropdown-menu {
			padding-left: 10px;

			.dropdown-item {
				text-align: left;
			}

			.dropdown-item:hover,
			.dropdown-item.active,
			.dropdown-item.show {
				background-color: transparent !important;
				color: #ddae3e !important;
			}
		}

		.dropright .dropdown-toggle::after {
			border-top: .3em solid;
			border-right: .3em solid transparent;
			border-bottom: 0;
			border-left: .3em solid transparent;
		}

		.lang {
			position: absolute;
			right: 0;
		}
	}
}
/*========================================
NavBar IconBar
==========================================*/

.navbar-toggler {
	position: relative;
	padding: 14px 10px;
	margin-top: 8px;
	margin-bottom: 8px;
	background-color: transparent;
	background-image: none;
	border: none;
	border-radius: 0px;
	-webkit-transform: translateX(100%);
	    -ms-transform: translateX(100%);
	        transform: translateX(100%);
	-webkit-transition: -webkit-transform .35s;
	transition: -webkit-transform .35s;
	-o-transition: transform .35s;
	transition: transform .35s;
	transition: transform .35s, -webkit-transform .35s;
	position: absolute;
	right: 0;
	z-index: 10;

	&:focus{
		outline: none;
	}
	.icon-bar {
		display: block;
		border-radius: 1px;
		-webkit-transition: 0.5s;
		-o-transition: 0.5s;
		transition: 0.5s;
		width: 30px;
		height: 3px;
		background-color: #cc0000 !important;
	}
	.icon-bar + .icon-bar {
		margin-top: 7px;
	}
	.icon-bar:nth-child(2) {
		-webkit-transform: translateY(7px) rotate(45deg);
			-ms-transform: translateY(7px) rotate(45deg);
				transform: translateY(7px) rotate(45deg);
	}
	.icon-bar:nth-child(3) {
		-webkit-transform: translateY(-3px) rotate(-45deg);
			-ms-transform: translateY(-3px) rotate(-45deg);
				transform: translateY(-3px) rotate(-45deg);
	}
	.icon-bar:nth-child(4) {
		-webkit-transform: translateY(3px);
			-ms-transform: translateY(3px);
				transform: translateY(3px);
	}
	&.collapsed .icon-bar {
		-webkit-transform: translateY(0px) rotate(0deg)!important;
			-ms-transform: translateY(0px) rotate(0deg)!important;
				transform: translateY(0px) rotate(0deg)!important;
		background-color: #888 !important;
	}
}
.navbar-toggler{
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
}
/*========================================
NavBar Resposive
==========================================*/

@media(max-width: 992px) {
	.navbar-default .navbar-collapse .navbar-nav>li>a {
		padding: 30px 15px;
		font-size: 18px;
	}
	.nav-cont .navbar-collapse {
		width: 100%;
	}
}
@media(max-width: 768px) {
	.navbar-default .navbar-collapse .navbar-nav .dropdown-menu {
		border: none;
		width: 100%;
	}
	.navbar-default .navbar-collapse .navbar-nav .dropdown-menu>li>a {
		color: #ccc;
	}
}
/*========================================================
		Footer
=========================================================*/
footer.footer{
	background: #ed6f5b;
	border-top: 8px solid #843d32;
	color: #fff;
}

/*========================================================
		Holders
=========================================================*/
.text-holder {
	height: 300px;
	margin: 0px;
}
.text-holder.holder-auto {
	height: auto;
	text-align: center;
}
.parallax>img{
	display: none;
	position: absolute;
}
.parallax p.lead,
.text-holder p.lead {
	color: #111;
	font-size: 28px;
}
.parallax h1,
.text-holder h1 {
	font-size: 82px;
	font-weight: 200;
	color: #111;
}
.text-holder:not(.holder-auto) .container {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	        transform: translateY(-50%);
	text-align: center;
	max-width: 100vw;
}
@media (max-width: 768px) {
	.parallax h1,
	.text-holder h1 {
		font-size: 56px;
	}
	.parallax p.lead,
	.text-holder p.lead {
		color: #111;
		font-size: 20px;
	}
}

/*
.google-sigin-btn{
	background: #4285f4;
	color: white;
	position: relative;
	padding-left: 45px;
	border-radius: 2px;
	max-height: 38px;
	white-space: nowrap;
	max-width: 100%;
	-o-text-overflow: ellipsis;
	   text-overflow: ellipsis;
	overflow: hidden;
}

.google-sigin-btn.single{
	padding: 0px;
}

.google-sigin-btn.single:before{
	position: static;
}

.google-sigin-btn:before{
	content: '';
	display: inline-block;
	width: 36px;
	height: 36px;
	background-image: url('../img/btn_google_icon.png');
	-webkit-transition: -webkit-filter .15s;
	transition: -webkit-filter .15s;
	-o-transition: filter .15s;
	transition: filter .15s;
	transition: filter .15s, -webkit-filter .15s;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
}

.google-sigin-btn:hover:before{
	-webkit-filter: brightness(90%);
	        filter: brightness(90%);
}

.google-sigin-btn:hover,
.google-sigin-btn:active{
	background: #3367d6;
	color: white;
}

.facebook-sigin-btn{
	background: #3b5998;
	color: white;
	position: relative;
	padding-left: 45px;
	border-radius: 2px;
	max-height: 38px;
	white-space: nowrap;
	max-width: 100%;
	-o-text-overflow: ellipsis;
	   text-overflow: ellipsis;
	overflow: hidden;
}

.facebook-sigin-btn:before{
	content: '';
	display: inline-block;
	width: 36px;
	height: 36px;
	background-image: url('../img/btn_facebook_icon.png');
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
}

.facebook-sigin-btn:hover:before{
	-webkit-filter: brightness(90%);
	        filter: brightness(90%);
}

.facebook-sigin-btn:hover,
.facebook-sigin-btn:active{
	background: #293e6a;
	color: white;
}
*/

/*==================================
Boton Borde
===================================*/

.btn.btn-border {
	border-radius: 1px;
	border: 2px solid #eb6841;
	color: #eb6841;
	background: transparent;
}
.btn.btn-border:active,
.btn.btn-borde.active {
	background-image: none;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.btn-border {
	color: #eb6841;
	background-color: transparent;
	border-color: #eb6841;
}
.btn-border:focus,
.btn-border.focus {
	color: #651F0A;
	background-color: rgba(235, 104, 65, 0.55);
	border-color: #CA3F15;
}
.btn-border:hover {
	color: #CA3F15;
	background-color: rgba(235, 104, 65, 0.35);
}
.btn-border:active,
.btn-border.active,
.open>.dropdown-toggle.btn-border {
	color: #651F0A;
	background-color: rgba(235, 104, 65, 0.55);
	border-color: #CA3F15;
}
.btn-borde:active:hover,
.btn-border.active:hover,
.open>.dropdown-toggle.btn-border:hover,
.btn-border:active:focus,
.btn-border.active:focus,
.open>.dropdown-toggle.btn-border:focus,
.btn-border:active.focus,
.btn-border.active.focus,
.open>.dropdown-toggle.btn-border.focus {
	color: #651F0A;
	background-color: rgba(235, 104, 65, 0.55);
	border-color: #CA3F15;
}
.btn-border:active,
.btn-border.active,
.open>.dropdown-toggle.btn-border {
	background-image: none;
}
.btn-border.disabled:hover,
.btn-border[disabled]:hover,
fieldset[disabled] .btn-border:hover,
.btn-border.disabled:focus,
.btn-border[disabled]:focus,
fieldset[disabled] .btn-border:focus,
.btn-border.disabled.focus,
.btn-border[disabled].focus,
fieldset[disabled] .btn-border.focus {
	background-color: rgba(0, 0, 0, .25);
	border-color: #ccc;
}
.btn-border .badge {
	color: #fff;
	background-color: #eb6841;
}
#goUp {
	color: #333 !important;
	position: absolute;
	right: 35px;
}
/*=======================================
			Block Quote
=======================================*/
blockquote {
	border: none;
	position: relative;
	display: inline-block;
}
blockquote p {
	font-size: 42px !important;
}
blockquote footer {
	font-size: 24px;
	color: white;
}
blockquote>p:before {
	content: '\f10d';
	font: normal normal normal 14px/1 FontAwesome;
	font-size: 54px;
	float: left;
	margin-right: 10px;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
blockquote>p:after {
	content: '\f10e';
	font: normal normal normal 14px/1 FontAwesome;
	font-size: 54px;
	float: right;
	margin-left: 5px;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@media(max-width: 768px) {
	blockquote p {
		font-size: 24px;
	}
	blockquote>p:before,
	blockquote>p:after {
		font-size: 36px;
	}
}
.gallery.no-border {
	padding: 0px;
}
.gallery.no-border .gallery-item {
	padding: 0px !important;
}
.parallax.no-height {
	height: auto;
}
.parallax.o-white::before,
.parallax.o-black::before {
	content: '';
	display: block;
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	margin: 0px;
	width: 100%;
	z-index: 2;
}
.parallax.o-white::before {
	background: rgba(255, 255, 255, 0.3);
}
.parallax.o-black {
	color: white;
}
.parallax.o-black::before {
	background: rgba(0, 0, 0, 0.3);
}
.parallax.blured>img {} .parallax .jumbotron {
	background: transparent;
	font-size: inherit;
}

.list-tree .list-group{
	margin: 0 -1.25rem;
	padding-left: 1.25rem;
}
.list-tree .list-group .list-group-item:first-child{
	border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.list-tree .list-group-item>.list-group{
	margin-top: 0.75rem;
	margin-bottom: -0.75rem;
}
.list-tree .list-group-item>.list-group>.list-group-item:not(.active){
	background: transparent;
}
.list-group-item:last-child{
	border-bottom: 0;
}

.mw-500{
	max-width: 500px;
}
.mw-400{
	max-width: 400px;
}
.mw-300{
	max-width: 300px;
}

.stack-buttons button{
	display: block;
	border-radius: 0;
}
.stack-buttons.small button{
	font-size: 0.5em !important;
}
.stack-buttons button:last-child{
	border-bottom-left-radius: 50%;
	border-bottom-right-radius: 50%;
}
.stack-buttons button:first-child{
	border-top-left-radius: 50%;
	border-top-right-radius: 50%;
}

div.loading{
	position: relative;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */ /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
						supported by Chrome and Opera */
}
div.loading::before{
	content: '';
	position: absolute;
	top: 0px;
	right: 0px;
	left: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,0.5);
	-webkit-backdrop-filter: blur(3px);
	border-radius: 5px;
	z-index: 100;
	-webkit-transition: all 2s;
	-o-transition: all 2s;
	transition: all 2s;
}
div.loading::after{
	content: '';
	position: absolute;
	top: 0px; bottom: 0px; left: 0px; right: 0px;
	z-index: 100;
	margin: auto;
	width: 48px;
	height: 48px;
	@extend .spinner-grow;
	color: $theme-primary;
}

.flex-shrink-1000 {
    -ms-flex-negative: 1000 !important;
    flex-shrink: 1000 !important;
}

.img-radio input[type="radio"]{
	display: none;
}
.img-radio label{
	border-radius: 0.5rem;
}
.img-radio input[type="radio"]:checked + label{
	background: #ffde74;
	color: #fff;
}

/*=========================================
			Responsive Ratio
=========================================*/
.responsive-ratio{
	width: 100%;
	position: relative; /* If you want text inside of it */
	overflow: hidden;
	background-position: center;
	background-size: cover;
}
.responsive-ratio>*{
	position: absolute;
	top: 0;left: 0;right: 0;bottom: 0;
	margin: auto;
	width: 100%; height: 100%;
}

img.cover{
	object-fit: cover;
	object-position: center;
}
img.contain{
	object-fit: contain;
	object-position: center;
}

.responsive-1-1{
	padding-top: 100%; /* 1:1 Aspect Ratio */
}
.responsive-4-3{
	padding-top: 75%; /* 4:3 Aspect Ratio */
}
.responsive-16-9{
	padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
.responsive-21-9{
	padding-top: 42.87%; /* 21:9 Aspect Ratio */
}

.list-group .list-group-item-transparent{
	background: transparent;
}

.fa-spin-once{
	-webkit-transition: all 0.6s ease-in-out;
	-moz-transition: all 0.6s ease-in-out;
	-o-transition: all 0.6s ease-in-out;
	-ms-transition: all 0.6s ease-in-out;
	transition: all 0.6s ease-in-out;
	-webkit-transform: rotate(360deg);
	-o-transform: rotate(360deg);
	-moz-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	transform: rotate(360deg);
}

img[lazy=loading] {
	/*your style here*/
	filter: blur(5px);
}
img[lazy=error] {
	/*your style here*/
}
img[lazy=loaded] {
	/*your style here*/
}