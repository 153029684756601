.scslider{
    position: relative;
    height: 400px;
    width: 100%;
    overflow: hidden;
}
.scs-inner {
    position: relative;
    min-width: 100%;
    height: 100%;
    left: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: 1s linear left;
    -o-transition: 1s linear left;
    transition: 1s linear left;
    white-space: nowrap;
}
.scs-inner > .slide {
    width: 100%;
    height: 100%;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    position: relative;
    text-align: center;
    -webkit-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
    min-width: 100%;
}

.scs-inited .scs-inner > .slide{
    min-width: auto;
}

.scs-inner > .slide .scs-img{
    height: 100%;
    width: 100%;
    display: block;
    -webkit-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
}
.scs-inner > .slide .scs-img > img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
/*.scs-inner.sliding > .slide{
    transform: scale(.8);
}*/
.scs-inner > .slide *{
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
    -webkit-touch-callout: none;
}
.scs-control {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 50px;
    width: 50px;
    opacity: 0.5;
    filter: alpha(opacity=50);
    font-size: 30px;
    color: #ffffff;
    margin: auto;
    margin-left: 30px;
    margin-right: 30px;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    background: rgba(0, 0, 0, 0.8);
    line-height: 50px;
}
.scs-control i {
    line-height: 50px;
}
.scs-control.left {
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.0001)));
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
}
.scs-control.right {
    left: auto;
    right: 0;
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.0001)), to(rgba(0, 0, 0, 0.5)));
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
}
.scs-control:hover,
.scs-control:focus {
    outline: 0;
    color: #ffffff;
    text-decoration: none;
    opacity: 0.9;
    filter: alpha(opacity=90);
}
.scs-control .icon-prev,
.scs-control .icon-next,
.scs-control .glyphicon-chevron-left,
.scs-control .glyphicon-chevron-right {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    z-index: 5;
    display: inline-block;
}
.scs-control .icon-prev,
.scs-control .glyphicon-chevron-left {
    left: 50%;
    margin-left: -10px;
}
.scs-control .icon-next,
.scs-control .glyphicon-chevron-right {
    right: 50%;
    margin-right: -10px;
}
.scs-control .icon-prev,
.scs-control .icon-next {
    width: 20px;
    height: 20px;
    line-height: 1;
    font-family: serif;
}
.scs-control .icon-prev:before {
    content: '\2039';
}
.scs-control .icon-next:before {
    content: '\203a';
}
.scs-control>.fa {
    line-height: 50px;
}

.scs-indicators {
    position: absolute;
    bottom: 0px;
    left: 50%;
    z-index: 15;
    width: 60%;
    margin-left: 0px;
    padding-left: 0;
    list-style: none;
    text-align: center;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.scs-indicators li {
    display: inline-block;
    width: 50px;
    margin: 1px;
    height: 4px;
    text-indent: -999px;
    cursor: pointer;
    background: rgba(255,255,255,0.5);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    overflow: hidden;
    border-radius: 1px;
}

.scs-indicators li:before{
    content: "";
    background-color: #fff;
    display: block;
    width: 0%;
    height: 100%;
    -webkit-transition: width 0s;
    -o-transition: width 0s;
    transition: width 0s;

}
.scs-indicators li.active:before {
    width: 100%;
    -webkit-transition: width 5s linear;
    -o-transition: width 5s linear;
    transition: width 5s linear;
}
.notransition{
    -webkit-transition: none 0s !important;
    -o-transition: none 0s !important;
    transition: none 0s !important;
}
.scs-caption-inner{
    position: absolute;
    top:  0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: auto;
}
.scs-caption {
    display: inline-block;
    position: relative;
    margin: auto;
    background: rgba(51, 51, 51, 0.85);
    padding: 25px;
    color: white;
    outline: 5px #333 solid;
    outline-offset: 3px;
    max-width: 80%;
    white-space: normal;
    text-align: center;
}
.scs-caption.animate:not(.animated){
    visibility: hidden;
}
.scs-caption#tinymce{
    display: block;
    visibility: visible;
    position: static;
}
.scs-caption h1{
    font-size: 42px;
    color: #f6f6f6;
    margin: auto;
}

.scs-caption h1,
.scs-caption h2,
.scs-caption h3{
    text-transform: uppercase;
}
@media (max-width: 767px){
    .scs-control{
        display: none;
    }
}
@media (min-width: 768px){
    .scs-caption {
        max-width: 70%;
    }
    .scs-caption h1{
        font-size: 48px;
    }
}
@media (min-width: 992px){
    .scs-caption h1{
        font-size: 64px;
    }
}
@media (min-width: 1200px){
    .scs-caption h1{
        font-size: 72px;
    }
}
/*.scs-caption:not(.animated){
    visibility: hidden;
}*/
.scs-slide a{
    width: 100%;
    height: 100%;
    display: block;
}
.scs-img{
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    margin: auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.scs-img img{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}